<template>
  <div class="mod-home" v-html="$store.state.common.paramsConfig.weclome">
    <h3>欢迎来到json配制平台</h3><img width="800px" src="http://pic1.win4000.com/wallpaper/1/58a154c7e5cd7.jpg" />
    <!-- <ul>
      <li>renren-fast-vue基于vue、element-ui构建开发，实现<a href="https://gitee.com/renrenio/renren-fast" target="_blank">renren-fast</a>后台管理前端功能，提供一套更优的前端解决方案</li>
      <li>前后端分离，通过token进行数据交互，可独立部署</li>
      <li>主题定制，通过scss变量统一一站式定制</li>
      <li>动态菜单，通过菜单管理统一管理访问路由</li>
      <li>数据切换，通过mock配置对接口数据／mock模拟数据进行切换</li>
      <li>发布时，可动态配置CDN静态资源／切换新旧版本</li>
      <li>演示地址：<a href="http://demo.open.renren.io/renren-fast" target="_blank">http://demo.open.renren.io/renren-fast</a> (账号密码：admin/admin)</li>
    </ul>
    <h3>获取帮助</h3>
    <ul>
      <li>官方社区：<a href="https://www.renren.io/community" target="_blank">https://www.renren.io/community</a></li>
      <li>前端Git地址：<a href="https://github.com/renrenio/renren-fast-vue" target="_blank">https://github.com/renrenio/renren-fast-vue</a></li>
      <li>后台Git地址：<a href="https://gitee.com/renrenio/renren-fast" target="_blank">https://gitee.com/renrenio/renren-fast</a></li>
      <li>代码生成器：<a href="https://gitee.com/renrenio/renren-generator" target="_blank">https://gitee.com/renrenio/renren-generator</a></li>
      <li>如需关注项目最新动态，请Watch、Star项目，同时也是对项目最好的支持</li>
    </ul>
    <h3>官方QQ群</h3>
    <ul>
      <li>高级群：324780204(大牛云集，跟大牛学习新技能)</li>
      <li>普通群：145799952(学习交流，互相解答各种疑问)</li>
    </ul> -->
  </div>
</template>

<script>
  export default {
    mounted() {
  
    }
  }
</script>

<style>
  .mod-home {
    line-height: 1.5;
  }
</style>

